import axios from "axios/index";

class QuestionsAPI {

    constructor() {
    }

    getNextQuestionOrById = (questionId, langUiName, isLiveSurvey, okCallback, koCallback) => {
        let params = {};
        if(typeof langUiName !== "undefined") {
            params.langUiName = langUiName;
        }

        params.isLiveSurvey = !!isLiveSurvey;

        return axios.get('widget/next/' + questionId, {params: params})
            .then(response => {
                okCallback && okCallback(response)
            })
            .catch(response => {
                koCallback && koCallback(response)
            });
    };

    getQuestionStats = (cardId, withAgeGroup, withGender, okCallback, koCallback) => {
        return axios.get('widget/' + cardId + '/stats' + '?withAgeGroup=' + withAgeGroup + '&withGender=' + withGender)
            .then(response => {
                okCallback && okCallback(response)
            })
            .catch(response => {
                koCallback && koCallback(response)
            });
    };

    getQuestionAnswerForUser = (cardId, okCallback, koCallback) => {
        return axios.get('widget/' + cardId + '/answer')
            .then(response => {
                okCallback && okCallback(response)
            })
            .catch(response => {
                koCallback && koCallback(response)
            });
    };

    saveQuestionAnswer = (cardId, answer,okCallback, koCallback) => {
        return axios.post('widget/' + cardId + '/answer', answer)
            .then(response => {
                okCallback && okCallback(response)
            })
            .catch(response => {
                koCallback && koCallback(response)
            });
    };

    getDefaultChoiceTypesByLanguageId = (langId, okCallback, koCallback) => {
        return axios.get('widget/defaultChoiceTypes/' + langId)
            .then(response => {
                okCallback && okCallback(response);
            })
            .catch(response => {
                koCallback && koCallback(response);
            });
    };

    getDefaultChoicesByLanguageId = (langId, okCallback, koCallback) => {
        return axios.get('widget/defaultChoices/' + langId)
            .then(response => {
                okCallback && okCallback(response);
            })
            .catch(response => {
                koCallback && koCallback(response);
            });
    };

    getProposedQuestions = (okCallback, koCallback) => {
        return axios.get('widget/proposedQuestions')
            .then(response => {
                okCallback && okCallback(response);
            })
            .catch(response => {
                koCallback && koCallback(response);
            });
    };

    proposeQuestion = (question, okCallback, koCallback) => {
        return axios.post('widget/propose/', question)
            .then(response => {
                okCallback && okCallback(response);
            })
            .catch(response => {
                koCallback && koCallback(response);
            });
    };
}

export default QuestionsAPI;