import React, {Component} from 'react';
import ModalFCM from "./ModalFCM";

class NotificationModal extends Component{

    constructor(props){
        super(props);
        this.state = {
            showModal : true
        };
    }

    componentWillReceiveProps(props){
        this.setState({
            showModal : true
        })
    }

    handleHide = () => {
        this.setState({showModal: false});
    };

    openQuest = (questId) =>{
        this.setState({showModal: false},()=>{
            window.open(window.location.origin + '/polls/' + questId + "?pushNotification=true");
        });
    };

    render(){
        let isLocationOk = window.location.href.endsWith("/") || window.location.href.endsWith("/login");
        const modal = (this.state.showModal && !isLocationOk)  ? (
            <ModalFCM>
                <div className="modalChild__header">
                    <div>
                        <h2 className="color-green">
                            {this.props.payload.notification.title}
                        </h2>
                        <a className="modalChild__header--close_icon" href='javascript:void(0)' onClick={() => this.handleHide()}>
                            <span className="modalChild__header--close_span">&times;</span></a>
                    </div>
                    <br/>
                    <div>
                        <p className="color-green">
                            {this.props.payload.notification.body}
                        </p>
                    </div>
                    <br/>
                    <div>
                        <a className="modal__button" href='javascript:void(0)' onClick={() => this.openQuest(this.props.payload.data.quest_id)}>{this.props.t('quests.indexJS.gpLinkP1',"Click here")}</a>
                    </div>
                </div>
            </ModalFCM>
        ) : null;

        return(<div id="innerModal">{modal}</div>)
    }

}

export default NotificationModal;