import React, {Component} from 'react';
import LoadingIndicator from "../../meta/LoadingIndicator";
import ReactTable from 'react-table';
import Select from "react-select";
import withGlobalContext from "../../hocs/withGlobalContext";
import PropTypes from "prop-types";
import moment from 'moment';
import ProposeQuestionColumnFilter from "../ProposeQuestion/ProposeQuestionColumnFilter";
import ProposeQuestionPagination from "../ProposeQuestion/ProposeQuestionPagination";
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import SidebarRightMobile from '../Sidebars/SidebarRightMobile';
import QuestiaUtils from '../../utils/QuestiaUtils';

const customDropdownStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: isFocused ? '#ffffff' : '#1e1e22',
            textAlign: 'right',
            padding: '5px 15px',
            fontSize: '16px',
            border: '1px solid transparent',
            borderColor: 'transparent transparent rgba(0, 0, 0, 0.1) transparent',
            cursor: 'pointer',
            userSelect: 'none',
            backgroundColor: isFocused ? '#b9d15e' : (isSelected ? 'rgba(0, 0, 0, 0.1)' : null),
            zIndex : 999,
            overflow: 'visible'
        }
    },
    control: (styles) => {
        return {
            ...styles,
            backgroundColor: '#ffffff',
            color: '#909090',
            fontSize: '12px',
            width : '165px',
            minWidth : '165px',
            textAlign: 'left',
            cursor: 'pointer',
            userSelect: 'none',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            borderStyle: 'none',
            '-webkit-box-shadow': '0px 0px 5px 5px #f8f8f8',
            '-moz-transition': 'all 0.3s ease-in-out',
            '-o-transition': 'all 0.3s ease-in-out',
            transition: 'all 0.3s ease-in-out',
            overflow: 'visible'
        }
    },
    input: (base, state) => ({
        ...base,
        fontFamily: 'Helvetica, sans-serif !important',
        fontSize: 13,
        fontWeight: 900,
        color: 'green'

    }),
    indicatorSeparator : base => ({
        ...base,
        display:'none'
    })
};

const settingsSlicker = {
    className: "carousel-inner",
    dots: false,
    infinite: true,
    slidesToShow: (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED ? 3 : 2),
    slidesToScroll: 1,
    accessibility: false,
    arrows: false,
    adaptiveHeight: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 2,
                centerPadding: '25px',
                centerMode: true,
                arrows: false,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                centerPadding: '25px',
                centerMode: true,
                arrows: false,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                centerPadding: '20px',
                centerMode: true,
                arrows: false,
            }
        }
    ]
};


class UserHistory extends React.Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired,
        cardsContext: PropTypes.object.isRequired
    };

    constructor(props){
        super(props);
        let momentLocale = (this.props.globalContext.userInfo.language) ? this.props.globalContext.userInfo.language.lang_moment_locale : window.questiaSpecificWebsiteConfig.MOMENT_LOCALE;
        if(typeof momentLocale !== 'undefined'){
            moment.locale(momentLocale);
        }
        this.state = {
            message : this.props.t('history.time.first_label',"Last 7 days"),
            userLogInfo : [],
            isLogInfoFetched : false,
            sumPoints : 0,
            noOfRaffleTickets : 0,
            noOfSurveysAnswered : 0,
            USER_LOG_DESCRIPTIONS : {
                initial_points : this.props.t('history.events.initial_points',"You received points for completing your profile settings"),
                update_profile_ticket : this.props.t('history.events.update_profile_ticket',"You received a ticket for completing your profile settings"),
                quest_completed : this.props.t('history.events.quest_completed',"You have completed the survey <span class = 'history_detail'> $survey_title </span>"),
                quest_profile : this.props.t('history.events.quest_profile',"You have completed the profile <span class = 'history_detail'> $profile_name </span>"),
                recommended_by_tickets : this.props.t('history.events.recommended_by_tickets',"You recommended a friend"),
                predictor_ticket : this.props.t('history.events.predictor_ticket',"You received a ticket for making a prediction"),
                predictor_accuracy : this.props.t('history.events.predictor_accuracy',"Event <span class = 'history_detail'> $event_name </span> has been closed"),
                birthday_ticket : this.props.t('history.events.birthday_ticket',"You received 1 ticket to the draw! Happy Birthday!"),
                tickets_conversion : this.props.t('history.events.tickets_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into <span class = 'history_detail_blue'> $no_tickets tickets </span>"),
                tickets_conversion_singular : this.props.t('history.events.tickets_conversion_singular',"You turned $no_points points into a ticket"),
                currency_conversion : this.props.t('history.events.currency_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into a <span class = 'history_detail_blue'> $prize_name </span>"),
                currency_conversion_null_prize : this.props.t('history.events.currency_conversion_null_prize',"You turned $no_points points into a prize."),
                birthday_points : this.props.t('history.events.birthday_points',"You received points for your birthday"),
                recommended_by_points :  this.props.t('history.events.recommended_by_points',"You received points because you invited a friend")
            },
            USER_LOG_TIME :[{
                value : '7',
                label : this.props.t('history.time.first_label',"Last 7 days")
                },
                {
                    value : '14',
                    label : this.props.t('history.time.second_label',"The last 14 days")
                },
                {
                    value : '30',
                    label : this.props.t('history.time.third_label',"The last 30 days")
                },
                {
                    value : '60',
                    label : this.props.t('history.time.fourth_label',"The last 60 days")
                },
                {
                    value : '90',
                    label : this.props.t('history.time.fifth_label',"The last 90 days")
                }],
            selectedLogTime : null,
            userData : this.props.globalContext.globals,
            historyQuests : []
        };
        this.columns = [
            {
                id: 'activity_description',
                accessor : d => d.description,
                sortable: false,
                Cell : props => (
                    <React.Fragment>
                        <span className="cell__details cell__details--date">{props.value.datetime}</span>
                        <span className="cell__history"><span className={props.value.color[0]}></span>{ReactHtmlParser(props.value.description)}</span>
                    </React.Fragment>
                ),
                Filter: props => <ProposeQuestionColumnFilter {...props} />,
                filterMethod: (filter, row) => {
                    let rowText = row.activity_description.description;
                    rowText = rowText.toUpperCase().replace(/\s*\<.*?\>\s*/g, '');
                    let filterValue = filter.value.filterValue;
                    filterValue = filterValue.toUpperCase();
                    if (!filterValue)
                        return true;
                    return rowText.includes(filterValue);
                }
            },
            {
                id : 'activity_amount',
                accessor : d => d.amount,
                sortable: false,
                Cell : props => (
                    <React.Fragment>
                        <span className={props.value.color[1]}>{props.value.amount_value}</span> <span className="cell__details--text">{props.value.amount_type}</span>
                    </React.Fragment>
                ),
                Filter : props => (<div className="select-selected"><Select
                    styles={customDropdownStyles}
                    value={this.state.selectedLogTime}
                    maxMenuHeight={190}
                    onChange={this.handleLogTimeChange}
                    options={this.state.USER_LOG_TIME}
                    placeholder={this.state.message}/></div>)
                ,
                filterMethod: (filter, row) => {

                },
                style: { overflow: 'visible',paddingBottom:'10px' }
            }
        ]
    }

    componentDidMount(){
        let lang = this.props.globalContext.functions.decideLanguage();
        this.props.i18n.changeLanguage(lang,(err, t) => {
            this.setState({
                message : t('history.time.first_label',"Last 7 days"),
                USER_LOG_DESCRIPTIONS : {
                    initial_points : t('history.events.initial_points',"You received points for completing your profile settings"),
                    update_profile_ticket : t('history.events.update_profile_ticket',"You received a ticket for completing your profile settings"),
                    quest_completed : t('history.events.quest_completed',"You have completed the survey <span class = 'history_detail'> $survey_title </span>"),
                    quest_profile : t('history.events.quest_profile',"You have completed the profile <span class = 'history_detail'> $profile_name </span>"),
                    recommended_by_tickets : t('history.events.recommended_by_tickets',"You recommended a friend"),
                    predictor_ticket :  t('history.events.predictor_ticket',"You received a ticket for making a prediction"),
                    predictor_accuracy : t('history.events.predictor_accuracy',"Event <span class = 'history_detail'> $event_name </span> has been closed"),
                    birthday_ticket : t('history.events.birthday_ticket',"You received 1 ticket to the draw! Happy Birthday!"),
                    tickets_conversion : t('history.events.tickets_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into <span class = 'history_detail_blue'> $no_tickets tickets </span>"),
                    tickets_conversion_singular : t('history.events.tickets_conversion_singular',"You turned $no_points points into a ticket"),
                    currency_conversion : t('history.events.currency_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into a <span class = 'history_detail_blue'> $prize_name </span>"),
                    currency_conversion_null_prize : t('history.events.currency_conversion_null_prize',"You turned $no_points points into a prize."),
                    birthday_points : this.props.t('history.events.birthday_points',"You received points for your birthday"),
                    recommended_by_points :  this.props.t('history.events.recommended_by_points',"You received points because you invited a friend")
                },
                USER_LOG_TIME :[{
                    value : '7',
                    label : t('history.time.first_label',"Last 7 days")
                },
                    {
                        value : '14',
                        label : t('history.time.second_label',"The last 14 days")
                    },
                    {
                        value : '30',
                        label : t('history.time.third_label',"The last 30 days")
                    },
                    {
                        value : '60',
                        label : t('history.time.fourth_label',"The last 60 days")
                    },
                    {
                        value : '90',
                        label : t('history.time.fifth_label',"The last 90 days")
                    }],
                selectedLogTime : {
                    value : '7',
                    label : t('history.time.first_label',"Last 7 days")
                },
            },()=>this.fetchUserDetails(parseInt(this.state.USER_LOG_TIME[0].value)))
        });
    }

    componentDidUpdate(prevProps){
        this.refreshLanguage(prevProps);
    }

    refreshLanguage = (prevProps) =>{
        if(this.props.globalContext.userInfo.language.lang_moment_locale !== prevProps.globalContext.userInfo.language.lang_moment_locale){
            let momentLang = this.props.globalContext.userInfo.language.lang_moment_locale;
            if(typeof momentLang !== 'undefined'){
                moment.locale(momentLang);
            }
            let lang = this.props.globalContext.functions.decideLanguage();
            this.props.i18n.changeLanguage(lang,(err, t) => {
                this.setState({
                    message : t('history.time.first_label',"Last 7 days"),
                    USER_LOG_DESCRIPTIONS : {
                        initial_points : t('history.events.initial_points',"You received points for completing your profile settings"),
                        update_profile_ticket : t('history.events.update_profile_ticket',"You received a ticket for completing your profile settings"),
                        quest_completed : t('history.events.quest_completed',"You have completed the survey <span class = 'history_detail'> $survey_title </span>"),
                        quest_profile : t('history.events.quest_profile',"You have completed the profile <span class = 'history_detail'> $profile_name </span>"),
                        recommended_by_tickets : t('history.events.recommended_by_tickets',"You recommended a friend"),
                        predictor_ticket :  t('history.events.predictor_ticket',"You received a ticket for making a prediction"),
                        predictor_accuracy : t('history.events.predictor_accuracy',"Event <span class = 'history_detail'> $event_name </span> has been closed"),
                        birthday_ticket : t('history.events.birthday_ticket',"You received 1 ticket to the draw! Happy Birthday!"),
                        tickets_conversion : t('history.events.tickets_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into <span class = 'history_detail_blue'> $no_tickets tickets </span>"),
                        tickets_conversion_singular : t('history.events.tickets_conversion_singular',"You turned $no_points points into a ticket"),
                        currency_conversion : t('history.events.currency_conversion',"You have converted <span class = 'history_detail_green'> $no_points points </span> into a <span class = 'history_detail_blue'> $prize_name </span>"),
                        currency_conversion_null_prize : t('history.events.currency_conversion_null_prize',"You turned $no_points points into a prize."),
                        birthday_points : t('history.events.birthday_points',"You received points for your birthday"),
                        recommended_by_points :  t('history.events.recommended_by_points',"You received points because you invited a friend")
                    },
                    USER_LOG_TIME :[{
                        value : '7',
                        label : t('history.time.first_label',"Last 7 days")
                    },
                        {
                            value : '14',
                            label : t('history.time.second_label',"The last 14 days")
                        },
                        {
                            value : '30',
                            label : t('history.time.third_label',"The last 30 days")
                        },
                        {
                            value : '60',
                            label : t('history.time.fourth_label',"The last 60 days")
                        },
                        {
                            value : '90',
                            label : t('history.time.fifth_label',"The last 90 days")
                        }],
                    selectedLogTime : {
                        value : '7',
                        label : t('history.time.first_label',"Last 7 days")
                    },
                },()=>{
                    this.fetchUserDetails(parseInt(this.state.USER_LOG_TIME[0].value));
                });
            });
        }
    };

    fetchUserDetails = (days) =>{
        let context = this;
        let lang = this.props.globalContext.userInfo.language.lang_moment_locale;
        lang = lang.toString().toUpperCase();
        this.props.globalContext.services.userAPI.getHistoryQuests(this.state.userData.userId,lang, function (response) {

            context.setState({
                historyQuests : response.data,
                userLogInfo : []
            },()=>{
                context.buildDetailsData(response.data);
                context.fetchUserLogHistory(days);
            });

            },
            function (error) {
                console.log(error);
            })
    };

    buildDetailsData = (historyQuests) => {
        let sumOfPoints = 0;

        let filteredSurveys = historyQuests.filter(elem => elem.quest_type !== 'forecast' && (elem.status === 'completed' || elem.status === 'speedster' || elem.status === undefined));

        for(let i = 0; i < filteredSurveys.length ; i++){
            sumOfPoints+=parseInt(filteredSurveys[i].quest_points);
        }

        this.setState({
            sumPoints : this.props.globalContext.userInfo.user.user_points,
            noOfRaffleTickets : this.props.globalContext.userInfo.tickets,
            noOfSurveysAnswered : filteredSurveys.length
        });

    };

    fetchUserLogHistory = (days)=> {
        let context = this;
        this.setState({
            isLogInfoFetched : false
        }, () =>{
            this.props.globalContext.services.userAPI.getUserLog(days, function (response) {
                    let formattedLogData = context.formatLogData(response.data);
                    context.setState({
                        userLogInfo: formattedLogData,
                        isLogInfoFetched : true
                    });
                },
                function (error) {
                    console.log(error);
                    context.setState({
                        isLogInfoFetched : true
                    });
                })
        });

    };

    findInfoForQuest = (questId) => {
        let value = '';
        for(let i = 0; i < this.state.historyQuests.length; i++){
            if((questId === this.state.historyQuests[i].quest_id) || (questId === this.state.historyQuests[i].q_quest_id)){
                if(this.state.historyQuests[i].quest_type === 'profile'){
                    value = this.state.USER_LOG_DESCRIPTIONS.quest_profile.replace("$profile_name",this.state.historyQuests[i].quest_title);
                    return value;
                }else{
                    if(this.state.historyQuests[i].status){
                        if(this.state.historyQuests[i].status === 'screenout'){
                            return null;
                        }
                    }
                    value = this.state.USER_LOG_DESCRIPTIONS.quest_completed.replace("$survey_title",this.state.historyQuests[i].quest_title);
                    return value;
                }
            }
        }
        return null;
    };

    findInfoForForecast = (forecastId) =>{
        let value = '';
        for(let i = 0; i < this.state.historyQuests.length; i++){
            if(forecastId === this.state.historyQuests[i].q_quest_id){
                value = this.state.USER_LOG_DESCRIPTIONS.predictor_accuracy.replace("$event_name",this.state.historyQuests[i].quest_description);
                return value;
            }
        }
        //there are invalid answers deleted after closing an event, so activity log records for this evenents should be omitted
        //please check server -> ForecastPersistenceManager
        return null;
    };

    buildLogItemDescription = (key,params)=>{

        let description = {};

        let greenColorClass = ["cell__buble bg--green","cell__details--number color--green"];
        let violetColorClass = ["cell__buble bg--violet_new","cell__details--number color--violet_new"];
        let blueColorClass = ["cell__buble bg--blue","cell__details--number color--blue"];
        let yellowColorClass = ["cell__buble bg--yellow","cell__details--number color--yellow"];


        switch (key){
            case "initial_points":
                description.color = blueColorClass;
                description.content = this.state.USER_LOG_DESCRIPTIONS.initial_points;
                description.value =  "+"+params.split(",")[0];
                break;
            case "update_profile_ticket":
                if (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED) {
                    description.color = blueColorClass;
                    description.content = this.state.USER_LOG_DESCRIPTIONS.update_profile_ticket;
                    description.value =  "+"+params.split(",")[0];
                }
                break;
            case "quest_points":
                description.color = greenColorClass;
                description.content = this.findInfoForQuest(params.split(",")[1]);
                description.value = "+"+params.split(",")[0];
                break;
            case "quest_tickets":
                if (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED) {
                    description.color = greenColorClass;
                    description.content = this.findInfoForQuest(params.split(",")[1]);
                    description.value = "+"+params.split(",")[0];
                }
                break;
            case "recommended_by_tickets":
                if (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED) {
                    description.color = violetColorClass;
                    description.content = this.state.USER_LOG_DESCRIPTIONS.recommended_by_tickets;
                    description.value = "+"+params.split(",")[0];
                }
                break;
            case "predictor_ticket":
                if (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED) {
                    description.color =  yellowColorClass;
                    description.content = this.state.USER_LOG_DESCRIPTIONS.predictor_ticket;
                    description.suffix = params.split(",")[1];
                    description.value =  "+"+params.split(",")[0];
                }
                break;
            case "predictor_accuracy":
                description.color =  yellowColorClass;
                description.content = this.findInfoForForecast(params.split(",")[2]);
                description.value =  params.split(",")[1] + '%';
                break;
            case "birthday_ticket":
                if (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED) {
                    description.color = blueColorClass;
                    description.content = this.state.USER_LOG_DESCRIPTIONS.birthday_ticket;
                    description.value =  "+"+params.split(",")[0];
                }
                break;
            case "qod_tickets":
                description.content = null;
                break;
            case "birthday_points":
                description.color = blueColorClass;
                description.content = this.state.USER_LOG_DESCRIPTIONS.birthday_points;
                description.value = "+" + params.split(",");
                break;
            case "recommended_by_points":
                description.color = violetColorClass;
                description.content = this.state.USER_LOG_DESCRIPTIONS.recommended_by_points;
                description.value = "+" + params.split(",");
                break;
            default:
                if(key.startsWith("Converted") && !key.endsWith("currency") && window.questiaSpecificWebsiteConfig.TICKETS_ENABLED){
                    let conversionValues = key.match(/\d+/g).map(Number);
                    description.color = blueColorClass;
                    if(conversionValues[1] === 1){
                        description.content = this.state.USER_LOG_DESCRIPTIONS.tickets_conversion_singular;
                    }else{
                        description.content = this.state.USER_LOG_DESCRIPTIONS.tickets_conversion;
                    }
                    description.content = description.content.replace("$no_points",conversionValues[0]);
                    description.content = description.content.replace("$no_tickets",conversionValues[1]);
                    description.value = "+" + conversionValues[1];
                }else if(key.startsWith("Converted") && key.endsWith("currency")){
                    description.color = greenColorClass;
                    let conversionValues = key.match(/\d+/g).map(Number);

                    if( params==='0'){
                        description.content = this.state.USER_LOG_DESCRIPTIONS.currency_conversion_null_prize;
                        description.content = description.content.replace("$no_points",conversionValues[0]);
                    }else{
                        description.content = this.state.USER_LOG_DESCRIPTIONS.currency_conversion;
                        description.content = description.content.replace("$no_points",conversionValues[0]);
                        description.content = description.content.replace("$prize_name",params.replace(/ *\([^)]*\) */g, ""));
                    }
                    description.value = "-" + conversionValues[0];
                }
                break;
        }
        return description;
    };

    prizesTypeMapper = (value) => {
        let prizes_types = [];
        if(parseInt(value.substring(1))>1){
            prizes_types['tickets'] = this.props.t('history.tokens.tickets',"tickets");
        }else{
            prizes_types['tickets'] = this.props.t('history.tokens.ticket',"ticket");
        }

        prizes_types['points'] = this.props.t('history.tokens.points',"points");
        prizes_types['money'] = this.props.t('history.tokens.points',"points");
        prizes_types['predictor'] = this.props.t('history.tokens.accuracy',"accuracy");

        return prizes_types;
    };

    formatLogData = (logData) => {
        let logDataArray = [];
        for(let i = 0; i < logData.length; i++){
            let actionHistory = this.buildLogItemDescription(logData[i].actionDetails,logData[i].actionParams);
            if(actionHistory.content !== null && !QuestiaUtils.isEmptyObj(actionHistory)){
                let type = this.prizesTypeMapper(actionHistory.value)[logData[i].actionType];
                logDataArray.push({
                    description :{
                        datetime : moment(logData[i].actionDate.date).format("DD MMMM YYYY"),
                        description : actionHistory.content,
                        color : actionHistory.color,
                        type : logData[i].actionType
                    },
                    amount:{
                        amount_value : actionHistory.value,
                        amount_type : type,
                        color : actionHistory.color,
                        type : logData[i].actionType,
                        timeStamps : this.state.USER_LOG_TIME
                    }
                });
            }
        }
        return logDataArray;
    };

    handleLogTimeChange = (selectedTime) =>{
        this.setState({
            selectedLogTime : selectedTime
        });
        this.fetchUserLogHistory(selectedTime.value);
    };

    render() {
        if(this.state.userLogInfo){
            return(
            <div className="main-col">

                <h1 className="pb-xs">{this.props.t('history.content.header',"My activity")}</h1>

                <p className="log-paragraph">{this.props.t('history.content.intro',"Here you can track the history of your activity and the flow of points and tickets according to the interactions you had on Questia.")}</p>

                <div className="user-log__section">
                    <div className="user-log__row user-log__row-js">
                        <Slider {...settingsSlicker}>
                            <div>
                                <div className="user-log__item">
                                    <span className="user-log__item__title">{this.props.t('history.content.surveys_message',"You have accumulated from polls:")}</span>
                                    <div className="user-log__item__content">
                                        <span className="content__buble bg--green"></span>
                                        <span className="content__number color--green">{this.state.sumPoints}</span>
                                        <span className="content__text">{this.props.t('history.content.points',"POINTS")}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                window.questiaSpecificWebsiteConfig.TICKETS_ENABLED &&
                                (
                                    <div>
                                        <div className="user-log__item">
                                            <span className="user-log__item__title">{this.props.t('history.content.tickets_message',"Tickets valid in the current month:")}</span>
                                            <div className="user-log__item__content">
                                                <span className="content__buble bg--blue"></span>
                                                <span className="content__number color--blue">{this.state.noOfRaffleTickets}</span>
                                                <span className="content__text">{this.state.noOfRaffleTickets===1 ? this.props.t('history.content.ticket',"TICKET")
                                                    : this.props.t('history.content.tickets',"TICKETS")}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div>
                                <div className="user-log__item">
                                    <span className="user-log__item__title">{this.props.t('history.content.no_surveys_message',"You replied to:")} </span>
                                    <div className="user-log__item__content">
                                        <span className="content__buble bg--violet_new"></span>
                                        <span className="content__number color--violet_new">{this.state.noOfSurveysAnswered}</span>
                                        <span className="content__text">{this.state.noOfSurveysAnswered > 1 ? this.props.t('history.content.surveys',"POLLS") :
                                            this.props.t('history.content.survey',"POLL")}</span>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </div>

                <div className="history__table">
                    <ReactTable
                        data={this.state.userLogInfo}
                        filterable={true}
                        columns={this.columns}
                        noDataText={this.state.isLogInfoFetched === false ?  this.props.t('history.content.loading_data',"The data is loaded") : this.props.t('history.content.no_data',"There is no historical data")}
                        defaultPageSize = {6}
                        pageSizeOptions = {[10, 20]}
                        PaginationComponent={ProposeQuestionPagination}
                    />
                </div>
                <SidebarRightMobile/>
            </div>);
        }
        return(
            <LoadingIndicator/>
        )
    }
}

export default withGlobalContext(withTranslation()(UserHistory));