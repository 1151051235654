import React from 'react';
import withGlobalContext from "../../hocs/withGlobalContext";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import WelcomeWidget from "../Widgets/WelcomeWidget";
import PredictorWidget from "../Widgets/PredictorWidget";
import FacebookWidget from "../Widgets/FacebookWidget";
import Slider from "react-slick";
import '../../../css/slick.css';
import PromoWidget from "../Widgets/PromoWidget";
import SurveysWidget from "../Widgets/SurveysWidget";
import QuestiaUtils from "../../utils/QuestiaUtils";

class SidebarRightMobile extends React.Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

    constructor(props){
        super(props);
    }

    buildWidgetsSlider() {
        const settingsCards = {
            className: "right-col--mobile",
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: true,
            centerPadding: '50px',
            responsive: [
                {
                    breakpoint: 9999,
                    settings: "unslick"
                },
                {
                    breakpoint: 1200
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerPadding: '25px'
                    }
                }
            ]
        };

        var sliderItems = [];
        if(window.questiaSpecificWebsiteConfig.SURVEYS_WIDGET_ACTIVE) sliderItems.push(<SurveysWidget/>);
        if(window.questiaSpecificWebsiteConfig.PROMO_WIDGET_ACTIVE) sliderItems.push(<PromoWidget/>);
        sliderItems.push(<WelcomeWidget/>);
        if(window.questiaSpecificWebsiteConfig.PREDICTOR_ACTIVE && QuestiaUtils.userHasPredictorRights(this.props.globalContext.globals.level)) sliderItems.push(<PredictorWidget/>);
        sliderItems.push(<FacebookWidget/>);

        return (
            <Slider {...settingsCards}>
                {sliderItems}
            </Slider>
        );
    };

    render() {
        return (
            <div>
                {this.buildWidgetsSlider()}
            </div>
        );
    }
}
export default withGlobalContext(withTranslation()(SidebarRightMobile));