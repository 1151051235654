import React, {Component} from 'react';
import PropTypes from "prop-types";
import queryString from 'query-string';
import withHeader from "../../hocs/withHeader";
import { withToastManager } from 'react-toast-notifications';
import LoadingIndicator from "../../meta/LoadingIndicator";
import withAuthenticationContext from "../../hocs/withAuthenticationContext";
import withGlobalContext from "../../hocs/withGlobalContext";
import { withTranslation } from 'react-i18next';
import TermsAndPrivacyModal from "../User/TermsAndPrivacyModal";
import {withRouter} from "react-router-dom";


class FacebookAuthComp extends Component {
    static propTypes = {
        authenticationContext: PropTypes.object.isRequired,
        globalContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        // Reading url query parameters
        const values = queryString.parse(props.location.search);
        const fbToken = props.location.state.accessToken;
        var userIsErgEmployee = (typeof values.utm_source !== "undefined" && values.utm_source === 'erg');

        this.state = {
            loading: true,
            fbToken: fbToken,
            userIsErgEmployee: userIsErgEmployee
        };
    }

    goBackAfterTimeout = () => {
        window.setTimeout(() => {
            this.props.history.goBack();
        }, 5000);
    };

    handleFacebookAuthentication = () => {
        const {functions} = this.props.authenticationContext;
        const {toastManager} = this.props;

        functions.defaultLogin(null, null, this.state.fbToken, null, null,null,
            (response) => {
                if (response.data.user_agrees_contact) {
                    functions.saveCredentials(response.data.token, response.data.user_id,
                        response.data.level, response.data.forecast_segment,
                        response.data.panel_segment, response.data.user_agrees_contact);
                    this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo,this.props.history);
                } else {
                    this.setState({
                        alreadyRegistered: true,
                        userId: response.data.user_id
                    }, () => {
                        this.toggleAgreementModal(true);
                    });
                }
            },
            (error) => {
                if (error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NOT_ACTIVE === error.data.code) {
                    functions.showLoginErrorMessageBasedOnErrorCode(error.data.code);
                    this.goBackAfterTimeout();
                } else if(error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NON_EXISTENT === error.data.code){
                    this.setState({
                        alreadyRegistered: false
                    }, () => {
                        this.toggleAgreementModal(true);
                    });
                } else {
                    toastManager.add(this.props.t('login.messages.login_facebook_error',"Error occurred while trying to login user using Facebook!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    this.goBackAfterTimeout();
                }
            }
        );
    };

    componentDidMount() {
        this.handleFacebookAuthentication();
    }

    handleFacebookRegistration = () => {
        const {functions} = this.props.authenticationContext;
        const {toastManager} = this.props;
        var {alreadyRegistered} = this.state;

        if (window.FB) {
            window.FB.api('/me?fields=name,email,first_name,last_name', (response) => {
                if (!response.email) {
                    toastManager.add(this.props.t('register.messages.register_facebook_no_email_received_error', "Error with Facebook register, no email address received from Facebook!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    this.goBackAfterTimeout();
                }

                const facebookResponse = {
                    email: response.email,
                    first_name: response.first_name,
                    last_name: response.last_name,
                    accessToken: this.state.fbToken
                };

                var userData = {
                    userId: this.state.userId,
                    userAgreement: true,
                    recommendedBy: this.props.globalContext.recommendedBy,
                    userLanguageString: this.props.globalContext.lang,
                    userErgEmployee: this.state.userIsErgEmployee
                };

                functions.selectAndExecuteFacebookRegistrationType(facebookResponse, alreadyRegistered, userData, this.props.globalContext.globals.redirectTo,this.props.history,
                    (success) => {
                        functions.saveCredentials(success.data.token, success.data.user_id,
                            success.data.level, success.data.forecast_segment,
                            success.data.panel_segment, success.data.user_agrees_contact);
                        this.props.globalContext.functions.clearRecommendedByCookie();
                        this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo,this.props.history);
                    },
                    (error) => {
                        this.props.toastManager.add(this.props.t('login.messages.register_facebook_error',"Error occurred while trying to register user using Facebook!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                    }
                );
            });
        } else {
            this.props.history.goBack();
        }
    };

    toggleAgreementModal = (showModal) => {
        this.setState({showAgreementModal: showModal});
    };

    render() {
        return (
            <section className="jumbotron">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <TermsAndPrivacyModal showModal={this.state.showAgreementModal} toggleShowModal={this.toggleAgreementModal} agreementConfirmFunction={this.handleFacebookRegistration} agreementCancelFunction={this.props.history.goBack}/>
                            <div className="login-form login pv-xs">
                                <p className="login-form__title pv-xs">Facebook Login...</p>
                                {
                                    this.state.loading && <LoadingIndicator/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withGlobalContext(withAuthenticationContext(withToastManager(withHeader(withTranslation()(withRouter(FacebookAuthComp))))));