export const initFacebookSdk = function () {
    let body = document.body;
    let facebookScript = document.createElement("script");
    facebookScript.async = true;
    facebookScript.defer = true;
    facebookScript.crossOrigin = "anonymous";
    facebookScript.src = "https://connect.facebook.net/en_US/sdk.js";
    body.appendChild(facebookScript);
};
export const fbAsyncInit = function() {
    window.FB.init({
        appId            : window.questiaSpecificWebsiteConfig.FACEBOOK_APP_ID,
        autoLogAppEvents : false,
        xfbml            : true,
        version          : 'v' + window.questiaSpecificWebsiteConfig.FACEBOOK_SDK_VERSION
    });
};