import React, {Component} from 'react';
import ModalAgreement  from "./ModalAgreement";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Agreement from "../Authentication/Agreement";

class TermsAndPrivacyModal extends Component{
    static propTypes = {
        agreementConfirmFunction: PropTypes.func.isRequired,
        toggleShowModal: PropTypes.func.isRequired,
        showModal: PropTypes.bool.isRequired
    };

    constructor(props){
        super(props);
        this.state = {
            agreeTerms: false,
            agreeProcessing: false
        };

        this.handleCheckboxEvent = this.handleCheckboxEvent.bind(this);
    }

    handleCheckboxEvent = (event) => {
        const target = event.target;
        const value = target.checked;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handleAgreementRefusal = () => {
        this.props.toggleShowModal(false);
        this.setState({
            agreeTerms: false,
            agreeProcessing: false
        }, () => {
            if(this.props.agreementCancelFunction) {
                this.props.agreementCancelFunction();
            }
        });
    };

    handleHide = () => {
        this.props.toggleShowModal(false);
        this.setState({
            agreeTerms: false,
            agreeProcessing: false
        }, () => {
            if(this.props.agreementCancelFunction) {
                this.props.agreementCancelFunction();
            }
        });
    };

    handleUserAgreement = () => {
        this.props.agreementConfirmFunction();
        this.props.toggleShowModal(false);
    };

    render(){
        var agreementValidationState = !this.state.agreeTerms || !this.state.agreeProcessing;

        const modalRoot = document.getElementById('agreement');
        modalRoot.style.display = this.props.showModal ? "flex" : "none";

        const modal = (this.props.showModal) ?
            (
                <ModalAgreement>
                    <React.Fragment>
                        <Agreement uniqueId="socialRegisterAgreementBoxes"
                                   isModalAgreement={true}
                                   agreeTerms={this.state.agreeTerms}
                                   agreeProcessing={this.state.agreeProcessing}
                                   handleCheckboxEvent={this.handleCheckboxEvent}/>
                        <div className="cta__wrapp text-center pt-xl pb-sm">
                            <button
                                type="button"
                                className={agreementValidationState ? "cta--normal cta--disabled cta--blue cta--fullwidth": "cta--normal cta--blue cta--fullwidth"}
                                onClick={this.handleUserAgreement}
                                disabled={agreementValidationState}>
                                {this.props.t('login.content.agreement_confirm',"Confirm")}
                            </button>
                        </div>
                        <div className="cta__wrapp text-center">
                            <a href="javascript:void(0)" onClick={this.handleAgreementRefusal}
                               className="cta--normal cta--red cta--fullwidth">{this.props.t('login.content.agreement_refuse',"Deny ")}</a>
                        </div>
                    </React.Fragment>
                </ModalAgreement>
            ) : null;

        return modal;
    }
}

export default withTranslation()(TermsAndPrivacyModal);