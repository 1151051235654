import React, {Component} from 'react';
import PropTypes from "prop-types";
import QuestionVerticalButtonsChoice from "../../cards/VerticalCard/QuestionVerticalButtonsChoice";
import QuestionClassicChoice from "../../cards/ClassicCard/QuestionClassicChoice";

export default class ChoiceRenderer extends Component {
    static propTypes = {
        card: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
    }

	verticalButtons = (card) => {
		const {choices} = card;

		return choices.map((choice, i) => {
			return (
                <QuestionVerticalButtonsChoice key={choice.value.toString() + new Date().getTime()}
                                               choice={choice}
                                               handleAnswerSelection={() => {}}/>
			);
		});
	};

	singleChoiceButtons = (card) => {
		const {choices} = card;
		const {id} = card;

		return choices.map((choice, i) => {
            var checked = false;

            return (
                <QuestionClassicChoice key={choice.value.toString() + new Date().getTime()}
                                       cardId={id}
                                       choice={choice}
                                       choiceType={"radio"}
                                       checked={checked}
                                       handleAnswerSelection={() => {}}/>
            );
        });
	};
	
    renderChoicesList = (card) => {
        const {type} = card;
		
		switch(type) {
			case window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_VERTICAL_BUTTONS:
				return (
					<div className="questia-question__item--inner px-sm-4">
						<div className="d-flex align-items-stretch questia-radio-box my-5">
							{this.verticalButtons(card)}
						</div>
					</div>
				);
			case window.questiaSpecificWebsiteConfig.CARD_TYPES.CARD_SINGLE_CHOICE_CLASSIC:
				return (
					<div className="question__item__form">
						{this.singleChoiceButtons(card)}
					</div>
				);
			default:
				return null;
		}
    };

    render() {
    	const {card} = this.props;
    	return this.renderChoicesList(card);
	}

};