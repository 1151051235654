import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import withHeader from "./components/hocs/withHeader";
import SidebarLeft from "./components/layout/Sidebars/SidebarLeft";
import SidebarRight from "./components/layout/Sidebars/SidebarRight";
import UserHistory from "./components/layout/User/UserHistory";
import ProposeQuestion from "./components/layout/ProposeQuestion/ProposeQuestion";

// LAZY IMPORTS
const Timeline = React.lazy(() => import("./components/layout/Timeline/Timeline"));
const CardPage = React.lazy(() => import("./components/layout/CardPages/CardPage"));
const TopTenCards = React.lazy(() => import("./components/layout/TopTenCards/TopTenCards"));
const CardStats = React.lazy(() => import("./components/layout/CardStats/CardStats"));
const AboutYou = React.lazy(() => import("./components/layout/AboutYou/AboutYou"));
const AccountSettings = React.lazy(() => import("./components/layout/User/AccountSettings"));
const UploadUserPhoto = React.lazy(() => import("./components/layout/User/UploadUserPhoto"));
const AboutMe = React.lazy(() => import("./components/layout/User/AboutMe"));
const SpendPoints = React.lazy(() => import("./components/layout/User/SpendPoints"));
const SurveysPage = React.lazy(() => import("./components/layout/SurveysPage/SurveysPage"));
const InviteFriends = React.lazy(() => import("./components/layout/InviteFriends/InviteFriends"));
const ThankYou = React.lazy(() => import("./components/layout/ThankYou/ThankYou"));

const Faq = React.lazy(() => import("./components/layout/Faq/Faq"));
const TermsAndConditions = React.lazy(() => import("./components/layout/TermsAndConditions/TermsAndConditions"));
const HowItWorks = React.lazy(() => import("./components/layout/HowItWorks/HowItWorks"));
const PrivacyPolicy = React.lazy(() => import("./components/layout/Privacy/PrivacyPolicy"));
const AboutUs = React.lazy(() => import("./components/layout/AboutUs/AboutUs"));
const Contact = React.lazy(() => import("./components/layout/Contact/Contact"));
const PrizesPage = React.lazy(() => import("./components/layout/Raffle/PrizesPage"));
const PageNotFound = React.lazy(() => import("./components/meta/PageNotFound"));

class App extends Component {
    state = {
        user: {}
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {

        var {match} = this.props;

        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <SidebarLeft/>
                        <Switch>
                            <Route exact path={`${match.path}/`} render={(props) => (<Timeline {...props}/>)}/>
                            <Route exact path={`${match.path}/cardPage/:id`} render={(props) => (<CardPage {...props}/>)}/>
                            <Route exact path={`${match.path}/top10`} render={(props) => (<TopTenCards {...props}/>)}/>
                            {
                                window.questiaSpecificWebsiteConfig.PROPOSE_QUESTION_ACTIVE &&
                                <Route exact path={`${match.path}/propose`} render={(props) => (<ProposeQuestion {...props}/>)}/>
                            }
                            <Route exact path={`${match.path}/stats/:cid`} render={(props) => (<CardStats {...props}/>)}/>
                            {
                                window.questiaSpecificWebsiteConfig.ABOUT_YOU_PAGE_ACTIVE &&
                                <Route exact path={`${match.path}/aboutYou`} render={(props) => (<AboutYou {...props}/>)}/>
                            }
                            <Route exact path={`${match.path}/faq`} component={Faq}/>
                            <Route exact path={`${match.path}/terms`} component={TermsAndConditions}/>
                            <Route exact path={`${match.path}/how`} render={(props) => (<HowItWorks {...props}/>)}/>
                            <Route exact path={`${match.path}/privacy`} component={PrivacyPolicy}/>
                            <Route exact path={`${match.path}/aboutUs`} render={(props) => (<AboutUs {...props}/>)}/>
                            <Route exact path={`${match.path}/history`} render={(props) => (<UserHistory {...props}/>)}/>
                            <Route exact path={`${match.path}/settings`} render={(props) => (<AccountSettings {...props}/>)}/>
                            <Route exact path={`${match.path}/photo`} render={(props) => (<UploadUserPhoto {...props}/>)}/>
                            <Route exact path={`${match.path}/profile`} render={() => (<AboutMe/>)}/>
                            <Route exact path={`${match.path}/spendpoints`} render={(props) => (<SpendPoints {...props}/>)}/>
                            <Route exact path={`${match.path}/surveys`} render={(props) => (<SurveysPage {...props}/>)}/>
                            <Route exact path={`${match.path}/contact`} render={(props) => (<Contact {...props}/>)}/>
                            <Route exact path={`${match.path}/prizes`} render={(props) => (<PrizesPage {...props}/>)}/>
                            <Route exact path={`${match.path}/invite`} render={(props) => (<InviteFriends {...props}/>)}/>
                            <Route exact path={`${match.path}/thankYou/:qid`} render={(props) => (<ThankYou {...props}/>)}/>
                            <Route exact render={(props) => (<PageNotFound {...props}/>)}/>
                        </Switch>
                        <SidebarRight/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withHeader(App);
