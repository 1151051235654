import React, {Component} from 'react';
import {CardsContext} from './CardsContext';
import withGlobalContext from "../hocs/withGlobalContext";
import PropTypes from "prop-types";
import axios from "axios/index";
import withCardsContext from "../hocs/withCardsContext";

class CardsProvider extends Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

    state = {
        cardsInfo: {},
        answersInfo: {},
        pinnedSurveys: [],
        profileSurveys: [],
        surveyPageData : [],
        functions: {
            updateCardsStatsList: (fetchedCard) => {
                var {cardsInfo} = this.state;
                cardsInfo[fetchedCard.id] = {};
                this.setState({cardsInfo: cardsInfo});
            },
            clearCardsStatsList: () => {
                this.setState({cardsInfo: {}});
            },
            fetchCardStatsAndSaveThem: (cardId, withAgeGroup, withGender) => {
                return this.props.globalContext.services.questionsAPI.getQuestionStats(cardId, withAgeGroup, withGender,
                    (success) => {
                        var {cardsInfo} = this.state;
                        cardsInfo[cardId] = {};
                        cardsInfo[cardId].stats = success.data;
                        cardsInfo[cardId].statsFiltered = withAgeGroup || withGender;

                        this.setState({cardsInfo: cardsInfo});
                    },
                    (error) => {
                        console.log(error)
                    })
            },
            getCardUserAnswer: (cardId, setStatsComponentState) => {
                const {answersInfo} = this.state;
                const answerList = answersInfo[cardId];
                if (answerList) {
                    setStatsComponentState(answerList);
                } else {
                    this.state.functions.fetchCardUserAnswerAndSaveIt(cardId, setStatsComponentState);
                }
            },
            fetchCardUserAnswerAndSaveIt: (cardId, setStatsComponentState) => {
                return this.props.globalContext.services.questionsAPI.getQuestionAnswerForUser(cardId,
                    (success) => {
                        const answerList = success.data.map(a => parseInt(a));
                        let {answersInfo} = this.state;
                        answersInfo[cardId] = answerList;

                        this.setState({answersInfo: answersInfo});
                        setStatsComponentState(answerList);
                    }, (error) => {
                        console.log(error)
                    });
            },
            saveCardAnswer: (cardId, answer) => {
                return this.props.globalContext.services.questionsAPI.saveQuestionAnswer(cardId, answer,
                    (success) => {
                        this.state.functions.fetchCardStatsAndSaveThem(cardId, false, false);
                    },
                    (error) => {
                        console.log("Failed!")
                    });
            },
            checkIfCardIsRendered: (cardId) => {
                var {cardsInfo} = this.state;
                return (cardsInfo[cardId] !== undefined);
            },
            fetchActiveSurveys: () => {
                const {userId} = this.props.globalContext.globals;
                return axios.get('user/' + userId + '/activequests')
                    .then(response => {

                        let surveyPageData = response.data.filter(survey =>
                            (survey.quest_type === "short" || survey.quest_type === "profile" || survey.quest_type === "test" || survey.quest_type === "standard"));
                        let generalProfileIndex = surveyPageData.findIndex(survey => survey.quest_id === 1);
                        if (generalProfileIndex !== -1) {
                            var generalProfile = surveyPageData[generalProfileIndex];
                            surveyPageData.splice(generalProfileIndex, 1);
                            surveyPageData.unshift(generalProfile);
                        }

                        /* Carduri sondaje profil */
                        var profileSurveys = response.data.filter(survey => survey.quest_type === "profile");
                        /* Carduri sondaje fixate pe wall (toate sondajele short plus primul sondaj profil) */
                        var pinnedSurveys = response.data.filter(survey => survey.quest_type === "short" || survey.quest_type === "standard");
                        if (profileSurveys.length > 0) {
                            var generalProfileSurveyIndex = profileSurveys.findIndex(survey => survey.quest_id === 1);
                            if (generalProfileSurveyIndex !== -1) {
                                pinnedSurveys.unshift(profileSurveys[generalProfileSurveyIndex]);
                                profileSurveys.splice(generalProfileSurveyIndex, 1);
                            }
                            else {
                                pinnedSurveys = pinnedSurveys.concat(profileSurveys[0]);
                                profileSurveys.shift();
                            }
                        }
                        this.setState({
                            pinnedSurveys: pinnedSurveys,
                            profileSurveys: profileSurveys,
                            surveyPageData: surveyPageData
                        });
                    })
                    .catch(response => console.log(response));
            }
        }
    };

    render() {
        return (
            <CardsContext.Provider
                value={{
                    cardsInfo: this.state.cardsInfo,
                    answersInfo: this.state.answersInfo,
                    pinnedSurveys: this.state.pinnedSurveys,
                    profileSurveys: this.state.profileSurveys,
                    surveyPageData: this.state.surveyPageData,
                    functions: this.state.functions
                }}>
                {this.props.children}
            </CardsContext.Provider>
        )
    }
}

export default withGlobalContext(withCardsContext(CardsProvider));