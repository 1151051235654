import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class PredictorWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const domain = window.location.hostname.startsWith("www") ? window.location.hostname.slice(3) : window.location.hostname;
        const predictorSiteUrl = "https://predictor" + (domain.startsWith(".") ? "" : ".") + domain;

        return (
            <a href={predictorSiteUrl} className="box bg--yellow text-center">
                <span className="box__title color--white pv-sm">{this.props.t('sidebarRight.widget_predictor.text1_1',"Come with us on")}<br></br><strong>{this.props.t('sidebarRight.widget_predictor.text1_2',"Questia Predictor")}</strong>{this.props.t('sidebarRight.widget_predictor.text1_3',"let's shape the future!")}</span>
                <img className="box__image mv-sm" src={"specific/images/woman_bike.png"} alt=""/>
            </a>
        );
    }
}

export default withTranslation()(PredictorWidget);