import axios from "axios/index";
import QuestiaUtils from "../../utils/QuestiaUtils";

class UserAPI {

    constructor() {
    }

    getUserLog = function(historyLogDays, okCallback, koCallback) {
        axios.get('user/log/' + historyLogDays)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response));
    };

    getUserDetails = (userId, okCallback, koCallback) => {
        axios.get('user/' + userId)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    getUserFcmTokens = (okCallback, koCallback) => {
        axios.get('getAllDevicesTokens/')
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    deleteAllFcmTokensForUser = (okCallback, koCallback) => {
        axios.post('deleteAllDeviceToken/')
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    updateUser = (user, okCallback, koCallback) => {
        axios.post('user/' + user.user_id, user)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    requestDeleteAccount = (userId, okCallback, koCallback) => {
        axios.post('requestAccountDeletion/' + userId)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    static checkIfEmailIsValid = (email, okCallback, koCallback) => {
        axios.get('user/email/isValid/' + email)
            .then(function (response) {
                okCallback && okCallback(response);
            })
            .catch(function (error) {
                koCallback && koCallback(error.response);
            })
    };

    getCounties = (okCallback, koCallback) => {
        axios.get('administrative-divisions/counties')
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    getCities = (countyId, okCallback, koCallback) => {
        axios.get('administrative-divisions/cities/' + countyId)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    getTicketPrices = (lang,okCallback, koCallback)=>{
        axios.get('ticketprices' + "/" + lang)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response));
    };

    getAnswers = (questId, okCallBack, koCallback) => {
      axios.get('quest/' + questId + '/answer')
          .then(response =>
          okCallBack && okCallBack(response))
          .catch(response =>
          koCallback && koCallback(response));
    };

    postAnswers = (answers, okCallBack, koCallback) => {
        axios.post('questions/answer', answers)
            .then(response =>
                okCallBack && okCallBack(response)
            )
            .catch(response =>
                koCallback && koCallback(response)
            );
    };

    spendUserPoints = (userId, points, index, option, ticketsToExchange, okCallback, koCallback) => {
        let postUrl = 'convert/' + userId;
        postUrl += '?points=' + points;
        postUrl += '&index=' + index;
        postUrl += '&option=' + option;
        if(typeof ticketsToExchange !== "undefined" && ticketsToExchange !== null){
            postUrl += '&ticketsToExchange=' + ticketsToExchange;
        }
        axios.post(postUrl)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response));
    };

    static getUserHasAslCompleted = (userId, okCallback, koCallback) => {
        axios.get(`user/${userId}/hasAslCompleted`)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response));
    }

    getHistoryQuests =  function(userId,lang, okCallback, koCallback) {
        axios.get('user/' + userId + '/logDetails/' + lang)
            .then(response =>
                okCallback && okCallback(response)
            )
            .catch(response =>
                koCallback && koCallback(response));
    };

    saveUserFCMToken = function(token, okCallback, koCallback){
        axios.post("saveDeviceToken/",token,{
            headers: {'Content-Type': 'text/plain'}
        })  .then(response =>
            okCallback && okCallback(response)
        )
            .catch(response =>
                koCallback && koCallback(response));
    };

    static defaultLogin = (username, password, fbToken, googleToken, vKontakteToken, aituAccessToken, browserFingerprint, okCallback, koCallback) => {
        var loginAuthorizationData = QuestiaUtils.encodeBase64(username + ":" + password);
        var loginData = {
            fbToken: fbToken,
            googleToken: googleToken,
            vKontakteToken: vKontakteToken,
            aituAccessToken: aituAccessToken,
            user_name: username,
            user_password: password,
            fingerprint: browserFingerprint,
            recommended_by: null
        };

        var authString = 'Basic ' + loginAuthorizationData;

        axios.post('/user/login', loginData,
            {
                headers: {Authorization: authString}
            })
            .then((response) => {
                okCallback && okCallback(response);
            })
            .catch((error) => {
                koCallback && koCallback(error.response);
            });
    };

    static defaultRegistration = (user, okCallback, koCallback) => {
        user.user_name = "";
        user.user_birthdate = "";
        user.user_mobile = "";
        user.user_city_id = "";
        user.user_county = "";
        user.user_street = "";
        user.user_street_number = "";
        user.user_postalcode = "";
        user.makeForecaster = window.questiaSpecificWebsiteConfig.MAKE_FORECASTER;

        axios.post('/user/register', user)
            .then((response) => {
                okCallback && okCallback(response);
            })
            .catch((error) => {
                koCallback && koCallback(error.response);
            })
    };
}

export default UserAPI;