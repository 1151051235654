import React, {Component} from 'react';
import {AuthenticationContext} from './AuthenticationContext';
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import axios from "axios/index";
import {withToastManager} from "react-toast-notifications";
import {withTranslation} from "react-i18next";
import UserAPI from "../API/UserAPI";
import AuthenticationUtils from "../../utils/AuthenticationUtils";

class AuthenticationProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        functions: {
            defaultLogin: (username, password, fbToken, googleToken, vKontakteToken,aituAccessToken, okCallback, koCallback) => {
                UserAPI.defaultLogin(username, password, fbToken, googleToken, vKontakteToken, aituAccessToken, this.state.browserFingerprint, okCallback, koCallback);
            },
            selectAndExecuteFacebookRegistrationType: (facebookResponse, alreadyRegistered, userData, redirectTo,history, okCallback, koCallback) => {
                var {functions} = this.state;
                if (alreadyRegistered) {
                    functions.defaultLogin(null, null, facebookResponse.accessToken, null, null,null,
                        (success) => {
                            functions.saveCredentials(success.data.token, success.data.user_id,
                                success.data.level, success.data.forecast_segment,
                                success.data.panel_segment, userData.userAgreement);
                            var user = {
                                user_id: userData.userId,
                                user_agrees_contact: userData.userAgreement,
                            };
                            functions.updateUser(user, success.data.user_id + ":" + success.data.token,
                                (success) => {
                                    this.state.functions.redirectAfterLogin(redirectTo,history);
                                },
                                koCallback
                            );
                        },
                        koCallback
                    );
                } else {
                    functions.facebookRegister(facebookResponse, userData, okCallback, koCallback);
                }
            },
            facebookLogin: (facebookResponse, okCallback, koCallback) => {
                var {functions} = this.state;
                functions.defaultLogin(null, null, facebookResponse.accessToken, null, null,null, okCallback, koCallback);
            },
            selectAndExecuteGoogleRegistrationType: (googleResponse, alreadyRegistered, userData, redirectTo,history, okCallback, koCallback) => {
                var {functions} = this.state;
                if (alreadyRegistered) {
                    functions.defaultLogin(null, null, null, googleResponse.accessToken, null,null,
                        (success) => {
                            functions.saveCredentials(success.data.token, success.data.user_id,
                                success.data.level, success.data.forecast_segment,
                                success.data.panel_segment, userData.userAgreement);
                            var user = {
                                user_id: userData.userId,
                                user_agrees_contact: userData.userAgreement
                            };
                            functions.updateUser(user, success.data.user_id + ":" + success.data.token,
                                (success) => {
                                    this.state.functions.redirectAfterLogin(redirectTo,history);
                                },
                                koCallback
                            );
                        },
                        koCallback
                    );
                } else {
                    functions.googleRegister(googleResponse, userData, okCallback, koCallback);
                }
            },
            googleLogin: (googleResponse, okCallback, koCallback) => {
                var {functions} = this.state;
                functions.defaultLogin(null, null, null, googleResponse.accessToken, null,null, okCallback, koCallback);
            },
            selectAndExecuteVKontakteRegistrationType: (vKontakteResponse, alreadyRegistered, userData, redirectTo, history, okCallback, koCallback) => {
                var {functions} = this.state;
                if (alreadyRegistered) {
                    functions.defaultLogin(vKontakteResponse.email, null, null, null, vKontakteResponse.accessToken, null,
                        (success) => {
                            functions.saveCredentials(success.data.token, success.data.user_id,
                                success.data.level, success.data.forecast_segment,
                                success.data.panel_segment, userData.userAgreement);
                            var user = {
                                user_id: userData.userId,
                                user_agrees_contact: userData.userAgreement,
                            };
                            functions.updateUser(user, success.data.user_id + ":" + success.data.token,
                                (success) => {
                                    this.state.functions.redirectAfterLogin(redirectTo,history);
                                },
                                koCallback
                            );
                        },
                        koCallback
                    );
                } else {
                    functions.vKontakteRegister(vKontakteResponse, userData, okCallback, koCallback);
                }
            },
            vKontakteLogin: (vKontakteToken, okCallback, koCallback) => {
                var {functions} = this.state;
                functions.defaultLogin(null, null, null, null, vKontakteToken,null, okCallback, koCallback);
            },
            aituRegister: (userPhoneNumber,userData,okCallback, koCallback) =>{
                var {functions} = this.state;
                var user = {};
                user.user_agrees_contact = userData.userAgreement;
                if(typeof userData.recommendedBy !== "undefined") {
                    user.user_recommended_by = userData.recommendedBy;
                }

                user.aituAccessToken = userData.accessToken;

                if(typeof userData.userLanguageString !== "undefined") {
                    user.user_language_string = userData.userLanguageString;
                } else {
                    user.user_language_string = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                }

                user.user_surname = (typeof userData.userFirstName === 'undefined' ||  userData.userFirstName === null) ? "" : userData.userFirstName;
                user.user_lastname = (typeof userData.userLastName === 'undefined' || userData.userLastName === null) ? "" : userData.userLastName;

                user.user_email = userPhoneNumber;
                user.user_erg_employee = (typeof userData.userErgEmployee === "undefined" ? false : userData.userErgEmployee);

                functions.defaultRegistration(user,
                    (success) => {
                        functions.defaultLogin(userPhoneNumber, null, null, null, null,userData.accessToken, okCallback, koCallback)
                    },
                    (error) => {
                        koCallback && koCallback('Error registering user with Aitu!');
                    }
                );
            },
            selectAndExecuteAituRegistrationType: (userPhoneNumber,alreadyRegistered, userData, redirectTo,history, okCallback, koCallback) => {
                var {functions} = this.state;
                if (alreadyRegistered) {
                    functions.defaultLogin(null, null, null, null, null,userData.accessToken,
                        (success) => {
                            functions.saveCredentials(success.data.token, success.data.user_id,
                                success.data.level, success.data.forecast_segment,
                                success.data.panel_segment, userData.userAgreement);
                            var user = {
                                user_id: userData.userId,
                                user_agrees_contact: userData.userAgreement,
                            };
                            functions.updateUser(user, success.data.user_id + ":" + success.data.token,
                                (success) => {
                                    this.state.functions.redirectAfterLogin(redirectTo,history);
                                },
                                koCallback
                            );
                        },
                        koCallback
                    );
                } else {
                    functions.aituRegister(userPhoneNumber,userData, okCallback, koCallback);
                }
            },
            saveCredentials: (token, userId, level, forecastSegment, panelSegment, user_agrees_contact) => {
                AuthenticationUtils.saveCredentials(token, userId, level, forecastSegment, panelSegment, user_agrees_contact, this.props.cookies);
            },
            clearCredentials: () => {
                const {cookies} = this.props;

                var options1 = {
                    sameSite: false,
                    path: '/'
                };
                cookies.remove('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE, options1);

                var domain = window.location.hostname.startsWith("www") ? window.location.hostname.slice(3) : window.location.hostname;
                var options = {
                    sameSite: false,
                    path: '/',
                    domain: domain
                };
                cookies.remove('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE, options);
                delete axios.defaults.headers.common['Authorization'];
            },
            defaultRegistration: (user, okCallback, koCallback) => {
                UserAPI.defaultRegistration(user, okCallback, koCallback);
            },
            updateUser: (user, auth, okCallback, koCallback) => {
                if (auth) {
                    axios.post('/user/' + user.user_id, user,
                        {headers: {"Authorization": auth}})
                        .then((response) => {
                            okCallback && okCallback(response);
                        })
                        .catch((error) => {
                            koCallback && koCallback(error.response);
                        })
                } else {
                    axios.post('/user/' + user.user_id, user)
                        .then((response) => {
                            okCallback && okCallback(response);
                        })
                        .catch((error) => {
                            koCallback && koCallback(error.response);
                        })
                }
            },
            facebookRegister: (facebookResponse, userData, okCallback, koCallback) => {
                var {functions} = this.state;
                var user = {};
                user.user_agrees_contact = userData.userAgreement;
                if(typeof userData.recommendedBy !== "undefined") {
                    user.user_recommended_by = userData.recommendedBy;
                }
                if (facebookResponse.email) {
                    user.user_email = facebookResponse.email;
                }
                else {
                    this.props.toastManager.add(this.props.t('register.messages.register_facebook_no_email_received_error',"Error with Facebook register, no email address received from Facebook!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    return;
                }
                if (facebookResponse.first_name) {
                    user.user_surname = facebookResponse.first_name;
                }
                if (facebookResponse.last_name) {
                    user.user_lastname = facebookResponse.last_name;
                }

                user.fbToken = facebookResponse.accessToken;
                user.googleToken = null;
                user.vKontakteToken = null;
                user.user_erg_employee = (typeof userData.userErgEmployee === "undefined" ? false : userData.userErgEmployee);
                if(typeof userData.userLanguageString !== "undefined") {
                    user.user_language_string = userData.userLanguageString;
                } else {
                    user.user_language_string = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                }

                functions.defaultRegistration(user,
                    (success) => {
                        functions.defaultLogin(null, null, facebookResponse.accessToken, null, null,null, okCallback, koCallback)
                    },
                    (error) => {
                        this.props.toastManager.add(this.props.t('login.messages.register_facebook_error',"Error occurred while trying to register user using Facebook!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        koCallback && koCallback('Error registering user with Facebook!');
                    }
                );
            },
            googleRegister: (googleResponse, userData, okCallback, koCallback) => {
                var {functions} = this.state;
                var user = {};
                user.user_agrees_contact = userData.userAgreement;
                if(typeof userData.recommendedBy !== "undefined") {
                    user.user_recommended_by = userData.recommendedBy;
                }
                if (googleResponse.email) {
                    user.user_email = googleResponse.email;
                }
                else {
                    this.props.toastManager.add(this.props.t('register.messages.register_google_no_email_received_error',"Error with Google register, no email address received from Google!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    return;
                }
                if (googleResponse.first_name) {
                    user.user_surname = googleResponse.first_name;
                }
                if (googleResponse.last_name) {
                    user.user_lastname = googleResponse.last_name;
                }

                user.googleToken = googleResponse.accessToken;
                user.fbToken = null;
                user.vKontakteToken = null;
                user.user_erg_employee = (typeof userData.userErgEmployee === "undefined" ? false : userData.userErgEmployee);
                if(typeof userData.userLanguageString !== "undefined") {
                    user.user_language_string = userData.userLanguageString;
                } else {
                    user.user_language_string = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                }

                functions.defaultRegistration(user,
                    (success) => {
                        functions.defaultLogin(null, null, null, googleResponse.accessToken, null,null, okCallback, koCallback)
                    },
                    (error) => {
                        this.props.toastManager.add(this.props.t('login.messages.register_google_error',"Error occurred while trying to register user using Google!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        koCallback && koCallback('Error registering user with Google!');
                    }
                );
            },
            googleErrorHandler: (data) => {
                const {toastManager} = this.props;
                switch (data.error) {
                    case "idpiframe_initialization_failed":
                        toastManager.add(this.props.t('login.messages.login_google_initialization_failed_error',"Error with Google login, failed to initialize Google module due to unauthorized domain app!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case "popup_closed_by_user":
                        //user closed popup
                        toastManager.add(this.props.t('login.messages.login_google_popup_closed_error',"Error with Google login, pop-up closed by user!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case "access_denied":
                        // user denied permissions
                        toastManager.add(this.props.t('login.messages.login_google_permisssions_denied_error',"Error with Google login, permissions denied by user!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case "immediate_failed":
                        toastManager.add(this.props.t('login.messages.login_google_error',"Error occurred while trying to login user using Google!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    default:
                        toastManager.add(this.props.t('login.messages.login_google_unknown_error',"Unknown Google login error, please contact Questia!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                }
            },
            vKontakteRegister: (vKontakteResponse, userData, okCallback, koCallback) => {
                var {functions} = this.state;
                var user = {};
                user.user_agrees_contact = userData.userAgreement;
                if(typeof userData.recommendedBy !== "undefined") {
                    user.user_recommended_by = userData.recommendedBy;
                }
                if (vKontakteResponse.email) {
                    user.user_email = vKontakteResponse.email;
                }
                else {
                    this.props.toastManager.add(this.props.t('register.messages.register_vk_no_email_received_error',"Error with Vkontakte register, no email address received from Vkontakte!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    return;
                }

                if (vKontakteResponse.first_name)
                    user.user_surname = vKontakteResponse.first_name;
                if (vKontakteResponse.last_name)
                    user.user_lastname = vKontakteResponse.last_name;
                user.vKontakteToken = vKontakteResponse.accessToken;
                user.fbToken = null;
                user.googleToken = null;
                user.user_erg_employee = (typeof userData.userErgEmployee === "undefined" ? false : userData.userErgEmployee);
                if(typeof userData.userLanguageString !== "undefined") {
                    user.user_language_string = userData.userLanguageString;
                } else {
                    user.user_language_string = window.questiaSpecificWebsiteConfig.DEFAULT_LANGUAGE;
                }

                functions.defaultRegistration(user,
                    (success) => {
                        functions.defaultLogin(vKontakteResponse.email, null, null, null, vKontakteResponse.accessToken,null, okCallback, koCallback)
                    },
                    (error) => {
                        this.props.toastManager.add(this.props.t('register.messages.register_vkontakte_error',"Error with VKontakte register, please contact Questia!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        koCallback && koCallback('Error registering user with VKontakte!');
                    }
                );
            },
            verifyAituUserAndGetUserData: (socialChannel, userCode, okCallback, koCallback) => {
                axios.post('/verifyCode/' + socialChannel + '/' + userCode, {code: userCode})
                    .then((response)=> {
                        okCallback && okCallback(response);
                    })
                    .catch((error) => {
                        koCallback && koCallback(error.response);
                    })
            },
            passwordEmailReset: (email, okCallback, koCallback) => {
                axios.get('/password/' + email)
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            passwordPhoneReset: (phone, okCallback, koCallback) => {
                axios.get('/password_phone/' + phone)
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            passwordChange: (password, passwordConfirm, userId, hash, okCallback, koCallback) => {
                var req = {
                    user_password_new: password,
                    user_password_repeat: passwordConfirm,
                    user_password_reset_hash: hash
                };

                axios.post('/password/' + userId, req)
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            setCallbackHashCookie: (callbackHash) => {
                const {cookies} = this.props;
                var now = new Date(),
                    // this will set the expiration to 10 years
                    exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
                var options = {
                    expires: exp,
                    sameSite: false,
                    path: '/'
                };
                cookies.set('callbackHash', callbackHash, options);
            },
            getCallbackHashFromCookie: () => {
                const {cookies} = this.props;
                return cookies.get('callbackHash');
            },
            clearCallbackHashCookie: () => {
                const {cookies} = this.props;
                var options = {
                    sameSite: false,
                    path: '/'
                };
                cookies.remove('callbackHash', options);
            },
            doesEmailRespectPattern: (email) => {
                if (!email) return false;
                let pattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
                return pattern.test(email);
            },
            checkIfEmailIsValid: (email, okCallback, koCallback) => {
                UserAPI.checkIfEmailIsValid(email, okCallback, koCallback);
            },
            checkIfPhoneNumberIsValid: (phoneNumber, okCallback, koCallback) => {
                axios.get('/remote/checkphone', {params: {user_phone: phoneNumber}})
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    });
            },
            sendSmsCode: (phoneNumberPayload, okCallback, koCallback) => {
                axios.post('/sendSmsCode/', phoneNumberPayload)
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            verifySmsCode: (smsPayload, okCallback, koCallback) => {
                axios.post('/validateSmsCode/', smsPayload)
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            isPasswordValid: (password) => {
            return password.length >= 6;
            },
            doPasswordsMatch: (password, confirmPassword) => {
                return password === confirmPassword;
            },
            isStringAName: (string) => {
                let pattern = /^[^\.\+\*\?\^\$\(\)\[\]\{\}\|\\!@#%&;:'"<>,/=_`~]+$/;
                return pattern.test(string);
            },
            isStringAPhoneNumber : (phoneNumber) =>{
                if(!phoneNumber) return false;
                if(window.questiaSpecificWebsiteConfig.PHONE_REGEX_PATTERNS && window.questiaSpecificWebsiteConfig.PHONE_REGEX_PATTERNS.length > 0) {
                    let test = false;
                    for(let i in window.questiaSpecificWebsiteConfig.PHONE_REGEX_PATTERNS) {
                        const phonePattern = new RegExp(window.questiaSpecificWebsiteConfig.PHONE_REGEX_PATTERNS[i]);
                        test = test || phonePattern.test(phoneNumber);
                    }
                    return test;
                } else {
                    let pattern =  /^[+\-0-9(). ]+$/;
                    return pattern.test(phoneNumber);
                }
            },
            activateAccount: (userId, hash, browserFingerprint, okCallback, koCallback) => {
                axios.post('/activation/' + userId + '/' + hash, {"browserFingerprint": browserFingerprint})
                    .then(function (response) {
                        okCallback && okCallback(response);
                    })
                    .catch(function (error) {
                        koCallback && koCallback(error.response);
                    })
            },
            setRedirectToCookie: (redirectTo) => {
                const {cookies} = this.props;
                var now = new Date(),
                    // this will set the expiration to 10 years
                    exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
                var options = {
                    expires: exp,
                    sameSite: false,
                    path: '/'
                };
                cookies.set('redirectTo', redirectTo, options);
            },
            getRedirectToFromCookie: () => {
                const {cookies} = this.props;
                return cookies.get('redirectTo');
            },
            clearRedirectToCookie: () =>{
                const {cookies} = this.props;
                var options = {
                    sameSite: false,
                    path: '/'
                };
                cookies.remove('redirectTo', options);
            },
            showLoginErrorMessageBasedOnErrorCode: (code) => {
                const {toastManager} = this.props;
                switch (code) {
                    case window.questiaSpecificWebsiteConfig.CODE_USER_NOT_ACTIVE:
                        toastManager.add(this.props.t('login.messages.login_user_inactive_error',"Inactive User!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case window.questiaSpecificWebsiteConfig.CODE_USER_NON_EXISTENT:
                        toastManager.add(this.props.t('login.messages.login_user_nonexistent_error',"Non-existent E-mail!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case window.questiaSpecificWebsiteConfig.CODE_LOGIN_WRONG_PASSWORD:
                        toastManager.add(this.props.t('login.messages.login_user_wrong_password_error',"Incorrect password!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    case window.questiaSpecificWebsiteConfig.CODE_LOGIN_USER_LOCKED:
                        toastManager.add(this.props.t('login.messages.login_user_locked_error',"Blocked User!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                        break;
                    default:
                        toastManager.add(this.props.t('login.messages.login_user_general_error',"Error while trying to login to the account, please contact Questia!"), {
                            appearance: 'error',
                            autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                            autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                        });
                }
            },
            redirectAfterLogin : (redirectTo, history) => {
                AuthenticationUtils.redirectAfterLogin(redirectTo, history);
            }
        }
    };

    constructor(props) {
        super(props);
        axios.defaults.baseURL = '/rest/';
    }

    componentDidMount = () => {
        AuthenticationUtils.calculateFingerprint((fingerprint) => {
            this.setState({
                browserFingerprint: fingerprint
            });
        });
    };

    render() {
        return (
            <AuthenticationContext.Provider
                value={{
                    functions: this.state.functions,
                    browserFingerprint: this.state.browserFingerprint
                }}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }
}

export default withCookies(withToastManager(withTranslation()(AuthenticationProvider)));