import UserAPI from "../services/API/UserAPI";
import axios from "axios";
import Fingerprint2 from "fingerprintjs2";

class AuthenticationUtils {
    static FINGERPRINT_DOMAIN = "@new.fingerprint";

    static handleGuestUserAuthentication = (cookies, history, okCallback, koCallback) => {
        const globalsGuest = cookies.get('globals_guest' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);
        if (globalsGuest) {
            axios.defaults.headers.common['Authorization'] = globalsGuest.authorization;
            okCallback && okCallback();
            return;
        }

        AuthenticationUtils.calculateFingerprint((fingerprint) => {
            const guestEmail = fingerprint + AuthenticationUtils.FINGERPRINT_DOMAIN;
            UserAPI.checkIfEmailIsValid(guestEmail, (response) => {
                const guestUserExist = response.data === false;
                if (guestUserExist) {
                    AuthenticationUtils.guestLogin(fingerprint, cookies, () => {
                        okCallback && okCallback();
                    }, (error) => {
                        koCallback && koCallback(error);
                    });
                } else {
                    AuthenticationUtils.guestRegister(fingerprint, () => {
                        AuthenticationUtils.guestLogin(fingerprint, cookies, () => {
                            okCallback && okCallback();
                        }, (error) => {
                            koCallback && koCallback(error);
                        });
                    }, (error) => {
                        koCallback && koCallback(error);
                    });
                }
            });
        })
    };

    static calculateFingerprint = (callback) => {
        const fingerprintOptions = {
            excludes: {
                indexedDb: true,
                openDatabase: true,
                adBlock: true,
                fontsFlash: true,
                fonts: true,
                plugins: true,
                enumerateDevices : true
            }
        };
        Fingerprint2.get(fingerprintOptions, (components) => {
            const values = components.map(function (component) {
                return component.value
            });
            callback(Fingerprint2.x64hash128(values.join(''), 31));
        });
    };

    static saveCredentials = (token, userId, level, forecastSegment, panelSegment, user_agrees_contact, cookies, isGuestUser) => {
        const globals = {
            userId: userId,
            authorization: userId + ":" + token,
            level: level,
            forecastSegment: forecastSegment,
            panelSegment: panelSegment,
            userAgreement: user_agrees_contact
        };

        const now = new Date(),
            // this will set the expiration to 10 years
            exp = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate()),
            domain = window.location.hostname.startsWith("www") ? window.location.hostname.slice(3) : window.location.hostname,
            options = {
                expires: exp,
                sameSite: "none",
                path: '/',
                domain: domain,
                secure: true
            };

        // options = {
        //     expires: exp,
        //     sameSite: false,
        //     path: '/',
        //     domain: domain
        // };

        if (isGuestUser) {
            cookies.set('globals_guest' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE, globals, options);
        } else {
            cookies.set('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE, globals, options);
        }
    };

    static redirectAfterLogin = (redirectTo, history) => {
        if (redirectTo) {
            if (redirectTo.includes("/polls/")) {
                window.location = window.location.origin + redirectTo;
            } else {
                window.location = redirectTo;
            }
        } else {
            window.location = '/app';
        }
    };

    static guestLogin = (fingerprint, cookies, okCallback, koCallback) => {
        const guestUsername = fingerprint + AuthenticationUtils.FINGERPRINT_DOMAIN;
        UserAPI.defaultLogin(guestUsername, fingerprint, null, null, null, null, fingerprint, (response) => {
            const { token, user_id, level, forecast_segment, panel_segment, user_agrees_contact } = response.data;
            axios.defaults.headers.common['Authorization'] = user_id + ":" + token;
            AuthenticationUtils.saveCredentials(token, user_id, level, forecast_segment, panel_segment, user_agrees_contact, cookies, true);

            okCallback && okCallback(response);
        }, koCallback);
    };

    static guestRegister = (fingerprint, okCallback, koCallback) => {
        const userData = {
            "user_surname": "NEW",
            "user_lastname": "man",
            "user_email": fingerprint + AuthenticationUtils.FINGERPRINT_DOMAIN,
            "user_password": fingerprint,
            "user_recommended_by": "82",
            "user_erg_employee": false
        };

        UserAPI.defaultRegistration(userData, okCallback, koCallback);
    };

    static getAvailableUserCookie = (cookies) => {
        const globalsGuest = cookies.get('globals_guest' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE),
            globals = cookies.get('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);
        return globals ? globals : (globalsGuest ? globalsGuest : undefined);
    }
}

export default AuthenticationUtils;