import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

class SurveysWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Link to="/app/surveys" class="box box--surveys bg_gradient--yellow2 text--black text-center">
                <div style={{padding: '25px'}}>
                    <span className="box__text pt-sm pb-xs">
                        <b>{this.props.t('sidebarRight.widget_surveys.text1',"Special draw with X winners")}</b>&nbsp;
                    </span>
                    <span className="box__text pb-xs">
                        {this.props.t('sidebarRight.widget_surveys.text2', "Just for the [month_year] draw, Y more winners will receive [prize_name]")}
                    </span>
                    <span className="box__text">
                        {this.props.t('sidebarRight.widget_surveys.text3', "We know you want to win, so fill out our surveys and increase your chances!")}
                    </span>
                </div>
                <img className="pt-sm" src={"specific/images/surveys_widget.png"} alt=""/>
            </Link>
        );
    }
}

export default withTranslation()(SurveysWidget);