import React, {Component} from 'react';
import PropTypes from "prop-types";
import queryString from 'query-string';
import withHeader from "../../hocs/withHeader";
import { withToastManager } from 'react-toast-notifications';
import LoadingIndicator from "../../meta/LoadingIndicator";
import withAuthenticationContext from "../../hocs/withAuthenticationContext";
import withGlobalContext from "../../hocs/withGlobalContext";
import { withTranslation } from 'react-i18next';
import TermsAndPrivacyModal from "../User/TermsAndPrivacyModal";
import {withRouter} from "react-router-dom";


class VkontakteAuthComp extends Component {
    static propTypes = {
        authenticationContext: PropTypes.object.isRequired,
        globalContext: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);

        // Reading url query parameters
        const values = queryString.parse(props.location.search);
        var userIsErgEmployee = (typeof values.utm_source !== "undefined" && values.utm_source === 'erg');

        if(!window.questiaSpecificWebsiteConfig.VK_APP_ENABLED || typeof values.code === 'undefined'){
            this.props.history.push("/login");
        }

        this.state = {
            loading: true,
            vkCode: values.code,
            userIsErgEmployee: userIsErgEmployee
        };
    }

    goBackAfterTimeout = () => {
        window.setTimeout(() => {
            this.props.history.goBack();
        }, 5000);
    };

    handleVKontakteAuthentication = (userData) => {
        var {functions} = this.props.authenticationContext;
        var {toastManager} = this.props;

        const vKontakteResponse = {
            email: userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name,
            accessToken: userData.user_id + ":" + userData.access_token
        };

        this.setState({vKontakteResponse: vKontakteResponse});

        functions.defaultLogin(userData.email, null, null, null, vKontakteResponse.accessToken, null,
            (response) => {
                if (response.data.user_agrees_contact) {
                    functions.saveCredentials(response.data.token, response.data.user_id,
                        response.data.level, response.data.forecast_segment,
                        response.data.panel_segment, response.data.user_agrees_contact);
                    this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo, this.props.history);
                }
                else {
                    this.setState({
                        alreadyRegistered: true,
                        userId: response.data.user_id
                    }, () => {
                        this.toggleAgreementModal(true);
                    });
                }
            },
            (error) => {
                if (error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NOT_ACTIVE === error.data.code) {
                    functions.showLoginErrorMessageBasedOnErrorCode(error.data.code);
                    this.goBackAfterTimeout();
                } else if(error.data.code && window.questiaSpecificWebsiteConfig.CODE_USER_NON_EXISTENT === error.data.code){
                    this.setState({
                        alreadyRegistered: false
                    }, () => {
                        this.toggleAgreementModal(true);
                    });
                } else {
                    toastManager.add(this.props.t('login.messages.login_vkontakte_error',"Error with VKontakte login, please contact Questia!"), {
                        appearance: 'error',
                        autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                        autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                    });
                    this.goBackAfterTimeout();
                }
            });
    };

    componentDidMount() {
        const {functions} = this.props.authenticationContext;
        functions.verifyAituUserAndGetUserData('vk',this.state.vkCode,
            (success) => {
                this.handleVKontakteAuthentication(success.data);
            }, (error)=>{
                this.props.history.push("/login?vk_failed_auth=true");
            });
    }

    handleVKontakteRegistration = () => {
        const {functions} = this.props.authenticationContext;
        var {alreadyRegistered, vKontakteResponse} = this.state;
        var userData = {
            userId: this.state.userId,
            userAgreement: true,
            recommendedBy: this.props.globalContext.recommendedBy,
            userLanguageString: this.props.globalContext.lang,
            userErgEmployee: this.state.userIsErgEmployee
        };

        functions.selectAndExecuteVKontakteRegistrationType(vKontakteResponse, alreadyRegistered, userData, this.props.globalContext.globals.redirectTo,this.props.history,
            (success) => {
                functions.saveCredentials(success.data.token, success.data.user_id,
                    success.data.level, success.data.forecast_segment,
                    success.data.panel_segment, success.data.user_agrees_contact);
                this.props.globalContext.functions.clearRecommendedByCookie();
                this.props.authenticationContext.functions.redirectAfterLogin(this.props.globalContext.globals.redirectTo,this.props.history);
            },
            (error) => {
                this.props.toastManager.add(this.props.t('register.messages.register_vkontakte_error',"Error with VKontakte register, please contact Questia!"), {
                    appearance: 'error',
                    autoDismiss: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS,
                    autoDismissTimeout: window.questiaSpecificWebsiteConfig.NOTIFICATIONS_AUTO_DISMISS_TIMEOUT
                });
            }
        );
    };

    toggleAgreementModal = (showModal) => {
        this.setState({showAgreementModal: showModal});
    };

    agreementDeclineFunction = () => {
        this.props.history.push("/login");
    };

    render() {
        return (
            <section className="jumbotron">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 offset-lg-4">
                            <TermsAndPrivacyModal showModal={this.state.showAgreementModal}
                                                  toggleShowModal={this.toggleAgreementModal}
                                                  agreementConfirmFunction={this.handleVKontakteRegistration}
                                                  agreementCancelFunction={this.agreementDeclineFunction}/>
                            <div className="login-form login pv-xs">
                                <p className="login-form__title pv-xs">Vkontakte Login...</p>
                                {
                                    this.state.loading && <LoadingIndicator/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withGlobalContext(withAuthenticationContext(withToastManager(withHeader(withTranslation()(withRouter(VkontakteAuthComp))))));