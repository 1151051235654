import React from 'react';
import {CardsContext} from "../services/CardsContext";

function withCardsContext(Component) {
    return function WrapperComponent(props) {
        return (
            <CardsContext.Consumer>
                {cardsContext => <Component {...props} cardsContext={cardsContext} />}
            </CardsContext.Consumer>
        );
    };
}

export default withCardsContext;