import React from 'react';
import axios from "axios";

class UserAvatar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            imgSrc:  false,
        };
    };

    componentDidMount () {
        axios.get('user/getupload/' + this.props.user_id + "?" + new Date().getTime())
            .then((success) => {
                if (success.data !== 404) {
                    this.setState({
                        imgSrc: "/rest/user/getupload/" + this.props.user_id + "?" + new Date().getTime()
                    })
                } else {
                    this.setState({
                        imgSrc: false
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    imgSrc: false
                })
            });
    }

    render() {
        let defaultImageSrc = null;

        if(!this.props.user_sex){
            defaultImageSrc = "specific/images/sex_male_avatar.png";
        }else{
            defaultImageSrc = "specific/images/sex_female_avatar.png";
        }

        return (
            <span className="profile__image">
                <img src={this.state.imgSrc ? this.state.imgSrc : defaultImageSrc}/>
            </span>
        );
    }
}

export default UserAvatar;