import firebase from 'firebase/app'
import 'firebase/messaging';
import React from 'react';
import ReactDOM from 'react-dom';
import NotificationModal from "./components/layout/User/NotificationModal";

export const initializeFirebase = () =>{
    const config = {
        apiKey: "AIzaSyCDO4LciLaYjKIy80rBjGw2yqoMSSeGOyI",
        authDomain: "questia-app-8d2bd.firebaseapp.com",
        databaseURL: "https://questia-app-8d2bd.firebaseio.com",
        projectId: "questia-app-8d2bd",
        storageBucket: "questia-app-8d2bd.appspot.com",
        messagingSenderId: "434756333746"
    };
    firebase.initializeApp(config);
    if (firebase.messaging.isSupported()){

        const messaging = firebase.messaging();

        messaging.onMessage(function (payload) {
            console.log('Notification received with page opened', payload);
            ReactDOM.render(<NotificationModal payload={payload}/>, document.querySelector("#modal"));
        });
    }
};