import React from 'react';
import { withTranslation } from 'react-i18next';

class UserDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let pointsText = this.props.user_points + " " + this.props.t('sidebarLeft.profile.points_text',"points");
        let ticketsText = (window.questiaSpecificWebsiteConfig.TICKETS_ENABLED ? (this.props.tickets + " " + this.props.t('sidebarLeft.profile.tickets_text',"ticket(s)")) : "");

        return (
                <div class="profile__points-row d-flex justify-content-center mt-4">
                    <div class="col col-user">
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="28" height="21.727"
                                viewBox="0 0 28 21.727">
                                <g transform="translate(0 0)">
                                    <g transform="translate(0)">
                                        <path
                                            d="M31.219,125.573l-4.832-5.468a.554.554,0,0,0-.415-.187H8.745a.554.554,0,0,0-.415.187L3.5,125.573a.554.554,0,0,0,.053.786l0,0,13.39,15.1a.554.554,0,0,0,.829,0l13.39-15.1,0,0a.554.554,0,0,0,.053-.786Zm-6.23-4.547L22.527,125l-3.947-3.97Zm-3.638,4.359H13.366l3.993-4.017Zm-5.215-4.359L12.19,125l-2.463-3.97Zm-7.478.38,2.468,3.979H5.142Zm-3.507,5.088H11.7l4.524,12.483Zm13.363,12.46,5.076-13.568c.106-.287-.311.106-.6,0s-.359-.676-.465-.389l-5.164,13.872-4.485-12.375H29.566Zm5.076-13.568,2.468-3.979,3.517,3.979Z"
                                            transform="translate(-3.358 -119.918)" fill="#ffcd32" />
                                    </g>
                                </g>
                            </svg></span>
                        {pointsText}
                    </div>
                    {window.questiaSpecificWebsiteConfig.TICKETS_ENABLED &&
                    <div class="col col-user">
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                viewBox="0 0 26 26">
                                <path
                                    d="M22.784,6.511l-.539.539a2.33,2.33,0,0,1-3.3-3.3l.539-.539L16.273,0,0,16.273l3.216,3.216.539-.539a2.33,2.33,0,0,1,3.3,3.3l-.539.539L9.727,26,26,9.727ZM8.613,22.732a3.856,3.856,0,0,0-5.345-5.345L2.155,16.273,16.273,2.155l1.114,1.114a3.856,3.856,0,0,0,5.345,5.345l1.114,1.114L9.727,23.845Z"
                                    fill="#ffcd32" />
                                <g transform="translate(14.242 9.832)">
                                    <g transform="translate(0 0)">
                                        <rect width="1.359" height="1.365"
                                            transform="translate(0 0.961) rotate(-45)" fill="#ffcd32" />
                                    </g>
                                </g>
                                <g transform="translate(12.058 7.647)">
                                    <g transform="translate(0 0)">
                                        <rect width="1.359" height="1.365"
                                            transform="translate(0 0.961) rotate(-45)" fill="#ffcd32" />
                                    </g>
                                </g>
                                <g transform="translate(16.427 12.017)">
                                    <g transform="translate(0 0)">
                                        <rect width="1.359" height="1.365"
                                            transform="translate(0 0.961) rotate(-45)" fill="#ffcd32" />
                                    </g>
                                </g>
                            </svg></span>
                        {ticketsText}
                    </div>
                    }
                </div>
        );
    }
}

export default withTranslation()(UserDetails);