import React, {Component} from 'react';
import withGlobalContext from "../../hocs/withGlobalContext";
import PropTypes, {instanceOf} from "prop-types";
import {withCookies, Cookies} from 'react-cookie';
import { withRouter } from 'react-router-dom';
import LoadingIndicator from "../../meta/LoadingIndicator";

class SecurityWrapper extends Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired,
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        const {cookies} = props;
        let globals = cookies.get('globals' + window.questiaSpecificWebsiteConfig.COOKIE_LOCALE);
        if (!globals) {
            this.props.globalContext.handleResetContextGlobalData();
            if(window.location.pathname !== "/"){
                if(window.location.pathname.includes("/app/cardPage")) {
                    this.props.history.push(window.location.pathname.replace("app/cardPage", "publicCardPage"));
                }
                else {
                    let redirectTo = "redirectTo=" + window.location.pathname;
                    this.props.history.push("/login?" + redirectTo);
                }
            } else {
                this.props.history.push("/login");
            }

        } else {
            if(!this.props.globalContext.isUserDataLoaded) {
                this.props.globalContext.handleInitContextGlobalData(false);
            }
        }
    }

    isPageBlockedByProfile = (pathname) => {
        //Checks if the page is in this unblocked pages list
        return  window.location.pathname !== "/app/profile" &&
            window.location.pathname !== "/app/settings" &&
            window.location.pathname !== "/app/faq" &&
            window.location.pathname !== "/app/terms" &&
            window.location.pathname !== "/app/how" &&
            window.location.pathname !== "/app/privacy" &&
            window.location.pathname !== "/app/contact" &&
            window.location.pathname !== "/app/aboutUs" &&
            window.location.pathname !== "/app/aboutYou" &&
            window.location.pathname !== "/app/spendpoints" &&
            window.location.pathname !== "/app/prizes"
    };

    render() {
        var {globalContext} = this.props;

        // Force redirect to profile if is incomplete
        if (window.questiaSpecificWebsiteConfig.BLOCKING_PROFILE
            && globalContext.isUserDataLoaded
            && this.isPageBlockedByProfile(window.location.pathname)
            && !globalContext.globals.hasUserCompletedProfile) {
            this.props.history.push("/app/profile");
        }
        if(globalContext.isUserDataLoaded) {
            return (
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
            )
        } else {
            return (
                <LoadingIndicator/>
            );
        }
    }
}

export default withCookies(withRouter(withGlobalContext(SecurityWrapper)));