import React from 'react';

class UserName extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <span id="userNameId" className="profile__name text-bold">{this.props.user_name.toUpperCase() + " " + this.props.user_lastname.toUpperCase()}</span>
            </React.Fragment>
        );
    }
}

export default UserName;