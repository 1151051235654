import React from 'react';
import UserAvatar from './UserAvatar';
import UserName from './UserName';
import UserDetails from './UserDetails';

class UserInfo extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        return (
          <React.Fragment>
              <UserAvatar user_id={this.props.userInfo.user.user_id} user_sex={this.props.userInfo.user.user_sex} />
              <UserName user_name={this.props.userInfo.user.user_surname} user_lastname={this.props.userInfo.user.user_lastname}/>
              <UserDetails user_points={this.props.userInfo.user.user_points} tickets={this.props.userInfo.tickets}/>
          </React.Fragment>
        );
    }
}

export default UserInfo;