import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopyright} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagramSquare, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import { withTranslation } from 'react-i18next';

const listStyles={
    display : 'inline-block',
    marginLeft : '30px'
};

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    getCurrentYear(){
        let currentYear;
        if(window.questiaSpecificWebsiteConfig.BUDDHIST_CALENDAR === true){
           currentYear = new Date().getFullYear() + window.questiaSpecificWebsiteConfig.BUDDHIST_CALENDAR_YEARS_OFFSET;
        }else{
           currentYear = new Date().getFullYear();
        }
        return currentYear;
    }

    render() {
        const {pathname} = window.location;
        const currentYear = this.getCurrentYear();
        return (
            <footer>
                <div className="footer__main">
                    <div className="container container--home  d-flex flex-wrap">
                        <ul className="footer__list col-12 col-md">
                            {(pathname !== '/contact') && <li className="footer__list__item"> <Link to="/contact">{this.props.t('footer.content.link_text_contact',"Contact")}</Link> </li>}
                            {(pathname !== '/privacy') && <li style={listStyles} className="footer__list__item"> <Link to="/privacy">{this.props.t('footer.content.link_text_privacy',"Privacy policy")}</Link> </li>}
                            {(pathname !== '/terms') && <li style={listStyles} className="footer__list__item"> <Link to="/terms">{this.props.t('footer.content.link_text_terms',"Terms and conditions")}</Link> </li>}
                            {(pathname !== '/faq') && <li style={listStyles} className="footer__list__item"> <Link to="/faq">{this.props.t('footer.content.link_text_faq',"FAQ")}</Link> </li>}
                        </ul>
                        <ul className="footer__list col-12 col-md-auto">
                            <li className="footer__list__item">
                                {window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL &&
                                <a target="_blank" href={window.questiaSpecificWebsiteConfig.FACEBOOK_PAGE_URL}
                                   className="footer-social"><i className="fa fa-facebook-square"><FontAwesomeIcon
                                    icon={faFacebookSquare}></FontAwesomeIcon></i></a>
                                }
                                {window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL &&
                                <a target="_blank" href={window.questiaSpecificWebsiteConfig.INSTAGRAM_PAGE_URL}
                                   className="footer-social"><i className="fa fa-instagram"><FontAwesomeIcon
                                    icon={faInstagramSquare}></FontAwesomeIcon></i></a>
                                }
                                {window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL &&
                                <a target="_blank" href={window.questiaSpecificWebsiteConfig.TWITTER_PAGE_URL}
                                   className="footer-social"><i className="fa fa-twitter-square"><FontAwesomeIcon
                                    icon={faTwitterSquare}></FontAwesomeIcon></i></a>
                                }
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="footer__legal">
                    <div className="container container--home">
                        <span className="footer__legal--left"><FontAwesomeIcon icon={faCopyright}></FontAwesomeIcon><span
                            className="footer__legal__year">{currentYear}</span> {this.props.t('quests.index.title',"Questia")}</span>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);