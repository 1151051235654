import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class WelcomeWidget extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="box box--text bg--white text-left">
                <span className="box__title pb-xs">{this.props.t('sidebarRight.widget_welcome.title',"Welcome to Questia 😊!")}</span>


                <span className="box__text pb-sm">{this.props.t('sidebarRight.widget_welcome.text1',"We can now start the adventure! We invite you to answer as many questions as possible and find out what others in the Questia community think about a range of topics.")}</span>
                <span className="box__text pb-sm"><strong>{this.props.t('sidebarRight.widget_welcome.text2',"There are more fun surprises to come. Stay tuned!")}</strong></span>
            </div>
        );
    }
}

export default withTranslation()(WelcomeWidget);