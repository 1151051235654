import React from 'react';
import withGlobalContext from "../../hocs/withGlobalContext";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import WelcomeWidget from "../Widgets/WelcomeWidget";
import ProposeQuestionWidget from "../Widgets/ProposeQuestionWidget";
import PredictorWidget from "../Widgets/PredictorWidget";
import FacebookWidget from "../Widgets/FacebookWidget";
import PromoWidget from "../Widgets/PromoWidget";
import SurveysWidget from "../Widgets/SurveysWidget";
import Sticky from 'react-stickynode';
import QuestiaUtils from "../../utils/QuestiaUtils";


class SidebarRight extends React.Component {
    static propTypes = {
        globalContext: PropTypes.object.isRequired
    };

	constructor(props){
		super(props);
	}

	render() {
        return (
            <Sticky className="right-col right-col--none" innerClass="right-col-container sidebar__inner" top={75}>
                {window.questiaSpecificWebsiteConfig.SURVEYS_WIDGET_ACTIVE &&
                    <SurveysWidget/>
                }

                {window.questiaSpecificWebsiteConfig.PROMO_WIDGET_ACTIVE &&
                    <PromoWidget/>
                }

                <WelcomeWidget/>

                {window.questiaSpecificWebsiteConfig.PROPOSE_QUESTION_ACTIVE &&
                    <ProposeQuestionWidget/>
                }

                {window.questiaSpecificWebsiteConfig.PREDICTOR_ACTIVE && QuestiaUtils.userHasPredictorRights(this.props.globalContext.globals.level) &&
                    <PredictorWidget/>
                }

                <FacebookWidget/>
            </Sticky>
        );
	}
}
export default withGlobalContext(withTranslation()(SidebarRight));